import React, { Component } from 'react'
import ThemeManager from './ThemeManager';

export class PaymentBilling extends Component {

    constructor(props){
        super(props);
     
        this.state = { nameValue: '', addressValue: '', pecValue: '', vatValue: '', capValue: '', cityValue: '', errorBorder: ['none', 'none', 'none', 'none'] };    
    }

    insertData = (e) => {
        var insert = e.target.value;
        insert =  this.capitalizeFirstLetter(insert);
        this.setState({ [e.target.name] : insert});
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    confirmBillingData = () => {
        if(this.state.nameValue != '' && this.state.addressValue != '' && this.state.capValue != '' && this.state.cityValue != ''){
            var billingInfo = JSON.stringify({
                business_name : this.state.nameValue,
                business_address : this.state.addressValue,
                business_cap : this.state.capValue,
                business_city : this.state.cityValue,
                business_vat : this.state.vatValue,
                business_pec : this.state.pecValue,
            });
            this.props.setInfo(billingInfo);
        }else{
            this.manageFillError();
        }
    }

    manageFillError = () => {

        let themeManager = ThemeManager.getInstance();
        const _errorColor = themeManager.getErrorColor();

        var errorBorder = this.state.errorBorder;
        if(this.state.nameValue == ''){
            errorBorder[0] = '1px solid '+_errorColor;
        }
        if(this.state.addressValue == ''){
            errorBorder[1] = '1px solid '+_errorColor;
        }
        if(this.state.cityValue == ''){
            errorBorder[2] = '1px solid '+_errorColor;
        }
        if(this.state.capValue == ''){
            errorBorder[3] = '1px solid '+_errorColor;
        }

        this.setState({ errorBorder: errorBorder });
    }

    resetBorderError = () => {
        this.setState({ errorBorder: ['none', 'none', 'none', 'none']});
    }

    focusInput = (e) => {
        
        var errorBorder = this.state.errorBorder;
        if([e.target.name]  == 'nameValue'){
            errorBorder[0] = 'none'
        }else if([e.target.name]  == 'addressValue'){
            errorBorder[1] = 'none'
        }else if([e.target.name]  == 'cityValue'){
            errorBorder[2] = 'none';
        }else if([e.target.name]  == 'capValue'){
            errorBorder[3] = 'none';
        }

        this.setState({ errorBorder: errorBorder });
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>

            <div style={{marginTop: '30px'}}>
            <p style={{color: _secondaryTextColor, fontSize: '14px'}}>inserisci i tuoi dati di fatturazione</p>
            <p style={{marginTop: '-12px', color: _secondaryTextColor, fontSize: '14px'}}>per completare il pagamento</p>
            </div>

            <div style={{marginTop: '25px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>            
            <div style={{ height: '45px', borderRadius: '8px', width: '250px',backgroundColor: _itemBackgroundColor }}>
             <input 
             style={{ border: this.state.errorBorder[0], borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '43px', width: '250px',
              outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
             name="nameValue"
             value={this.state.nameValue}
             placeholder="Ragione sociale"
             onChange={this.insertData} 
             onFocus={this.focusInput}
             />
             </div>            
            </div>


            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>            
            <div style={{ height: '45px', borderRadius: '8px', width: '250px',backgroundColor: _itemBackgroundColor }}>
             <input 
             style={{ border: this.state.errorBorder[1], borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '43px', width: '250px',
              outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
             name="addressValue"
             value={this.state.addressValue}
             placeholder="Indirizzo (Via, Piazza, ...)"
             onChange={this.insertData}
             onFocus={this.focusInput}
             />
            </div>         
            </div>


            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>            
            <div style={{ height: '45px', borderRadius: '8px', width: '117.5px',backgroundColor: _itemBackgroundColor }}>
             <input 
             style={{ border: this.state.errorBorder[2], borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '43px', width: '117.5px',
              outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
             name="cityValue"
             value={this.state.cityValue}
             placeholder="Città"
             onChange={this.insertData}
             onFocus={this.focusInput}
             />
            </div> 
            <div style={{ marginLeft: '15px', height: '45px', borderRadius: '8px', width: '117.5px',backgroundColor: _itemBackgroundColor }}>
             <input 
             style={{ border: this.state.errorBorder[3], borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '43px', width: '117.5px',
              outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
             name="capValue"
             value={this.state.capValue}
             placeholder="CAP"
             onChange={this.insertData}
             onFocus={this.focusInput}
             />
            </div>         
            </div>


            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>            
            <div style={{ height: '45px', borderRadius: '8px', width: '250px',backgroundColor: _itemBackgroundColor }}>
             <input 
             style={{ border: 'none', borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '43px', width: '250px',
              outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
             name="vatValue"
             value={this.state.vatValue}
             placeholder="Partita Iva"
             onChange={this.insertData}
             />
            </div>         
            </div>

            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>            
            <div style={{ height: '45px', borderRadius: '8px', width: '250px',backgroundColor: _itemBackgroundColor }}>
             <input 
             style={{ border: 'none', borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '43px', width: '250px',
              outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
             name="pecValue"
             value={this.state.pecValue}
             placeholder="PEC o SDI"
             onChange={this.insertData}
             />
            </div>         
            </div>

            <div><div  style={{ marginTop: '5px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.confirmBillingData}
            >

                    Procedi</p>
                    </div>
                    </div>

            </div>
        )
    }
}

export default PaymentBilling
