import React, { Component } from 'react'
import ThemeManager from './ThemeManager';
import UserManager from './UserManager';
import MainInfo from './MainInfo';
import Payment from './Payment';
import PaymentComplete from './PaymentComplete';

export class PaymentManager extends Component {

    constructor(props){
        super(props);

        var token = '';
        if(props.user != null){
            token = props.user.token;
        }
        this.state = { token: token, user: props.user, info: props.info, payment: props.payment};
        
      }

    render() {


        return (
            <div>
                
            

                <MainInfo info={this.state.info} />
                

                {
                   this.state.user === null || (this.state.user !== null && (this.state.user.birth !== 'ok' || this.state.user.sex !== 'ok' ))
                   ? (
                    <UserManager user={this.state.user} /> 
                   )
                   : (
                       null
                   )
               }


                { (this.state.payment == null && this.state.user != null)  &&
                <Payment token={this.state.token} info={this.state.info} />
                }

                { (this.state.payment != null && this.state.user != null) &&
                <PaymentComplete payment={this.state.payment} info={this.state.info} />
                }

            </div>
        )
    }
}

export default PaymentManager
