import React, { Component } from 'react'
import BirthDate from './BirthDate';
import Sex from './Sex';

export class PersonalData extends Component {


    constructor(props){
        super(props);

        this.state = { userInfo: props.user};
       
    }


    render() {
        return (
            <div>
             
             {
                this.state.userInfo.birth !== 'ok'
            ? (
                    <BirthDate user={this.state.userInfo} />
                )
            : (
                 null
            )
            }

            {
                this.state.userInfo.sex !== 'ok' && this.state.userInfo.birth === 'ok'
            ? (
                    <Sex user={this.state.userInfo} />
                )
            : (
                 null
            )
            }
                  
              
            </div>
        )
    }
}

export default PersonalData
