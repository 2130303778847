import React, { Component } from 'react'
import PhoneNumber from './PhoneNumber';
import NumberConfirmation from './NumberConfirmation';
import Register from './Register';
import * as rdd from 'react-device-detect';

export class Login extends Component {

    constructor(props){
        super(props);
        var prefix = props.prefix;
        var phone = props.phone;
        var canEdit = true;

        if(phone=== undefined || prefix=== undefined || prefix === null || phone === null){
            prefix = '39';
            phone = '';
        }else{
            canEdit = false;
        }
     
        this.state = { showPhoneNumber: true, showConfirmation: false, showName: false, phonenumber: phone, prefix: prefix, token: '', coderror: false, canEdit: canEdit, isAvailable: true};
        
    }


    doLogin = (number, prefix) => {
        if(this.state.isAvailable){
            this.setState({ isAvailable: false });
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
                phone: number,
                prefix: prefix
              })
            };
          
                const apiUrl = 'https://getfy-business.com/user/v3/login/';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            this.setState({ showPhoneNumber: false, showConfirmation: true, phonenumber: number, prefix: prefix, token: r.token, isAvailable: true});
          }).catch(e => {
             
            window.location.reload();
             
          });
        }
    }

    showLogin = (e) => {
        this.setState({ showPhoneNumber: true, showConfirmation: false, showName: false, phonenumber: '', prefix: '', isAvailable: true});
    }

    verifyCode = (code) => {
        if(this.state.isAvailable){

            this.setState({ coderror: false, isAvailable: false });
        var datainfo = rdd.osName+'-'+rdd.osVersion+'-'+rdd.fullBrowserVersion+'-'+rdd.browserName+'-'+rdd.mobileVendor+'-'+rdd.mobileModel+'-'+rdd.getUA+'-'+rdd.deviceType;
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
                token: this.state.token,
                code: code,
                info: datainfo
              })
            };
          
        const apiUrl = 'https://getfy-business.com/user/v3/login/code';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            if(r.info===0){
               localStorage.setItem('session_token', r.token);
               window.location.reload();
            }else{
                this.setState({ showPhoneNumber: false, showConfirmation: false, showName: true, token: r.token, isAvailable: true});
            }
            
          }).catch(e => {
              this.setState({ coderror: true, isAvailable: true});
          });
        }
       
    }

    registerNow = (name,surname) => {
        if(this.state.isAvailable){

        this.setState({ coderror: false, isAvailable: false });
        var datainfo = rdd.osName+'-'+rdd.osVersion+'-'+rdd.fullBrowserVersion+'-'+rdd.browserName+'-'+rdd.mobileVendor+'-'+rdd.mobileModel+'-'+rdd.getUA+'-'+rdd.deviceType;
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
                token: this.state.token,
                name: name,
                surname: surname,
                info: datainfo
              })
            };
          
                const apiUrl = 'https://getfy-business.com/user/v3/login/register';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            
            localStorage.setItem('session_token', r.token);
            window.location.reload();
     
          }).catch(e => {
              this.setState({ coderror: true, isAvailable: true});
          });

        }
    }


    render() {
        return (
            <div>
                
                {
                    this.state.showPhoneNumber
                    ? (
                        <PhoneNumber prefix={this.state.prefix} phone={this.state.phonenumber} canEdit={this.state.canEdit} login={this.doLogin} />
                    )
                    : (
                        null
                    )
                }


                {
                    this.state.showConfirmation
                    ? (
                        <NumberConfirmation error={this.state.coderror} canEdit={this.state.canEdit} cancel={this.showLogin} number={this.state.phonenumber} prefix={this.state.prefix} verify={this.verifyCode} />
                    )
                    : (
                        null
                    )
                }


                {
                    this.state.showName
                    ? (
                        <Register error={this.state.coderror} cancel={this.showLogin} register={this.registerNow} />
                    )
                    : (
                        null
                    )
                }

            </div>
        )
    }
}

export default Login
