import logo from './logo.svg';
import logoWhite from './logo-white.svg';
import logoBlack from './logo-black.svg';
import './App.css';
import { Component } from 'react';
import ThemeManager from './ThemeManager';
import MetaTags from 'react-meta-tags';
import PaymentManager from './PaymentManager';

class App extends Component {
  
  constructor(props){
    super(props);
    
    //setInterval(() => this.showProducts(),10);
    var code = window.location.href.replace(window.location.origin, "");
    code = code.replace("/", "");
   

    this.state = { code: code, isReady: false, userInfo: null, generalInfo: null, paymentInfo: null, logo: 'light', tabTitle: ''};
    
  }


  componentDidMount () {
    this.getCodeInfo(this.state.code);
  }

  getCodeInfo = (code) => {


    var sessiontoken = localStorage.getItem('session_token');
    if(sessiontoken === null){
      sessiontoken = '';
    }
    

    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
                code: code,
                token: sessiontoken
              })
      };
    
          const apiUrl = 'https://getfy-business.com/user/v3/pay/code';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      
      let themeManager = ThemeManager.getInstance();

      if(r.theme !== undefined && r.theme !== null){
        themeManager.setBackgroundColor(r.theme.background_color);
        themeManager.setSelectionColor(r.theme.selection_color);
        themeManager.setMainTextColor(r.theme.main_text_color);
        themeManager.setSecondaryTextColor(r.theme.secondary_text_color);
        themeManager.setButtonTextColor(r.theme.button_text_color);
        themeManager.setItemBackgroundColor(r.theme.item_background_color);
        themeManager.setErrorColor(r.theme.error_color);
      }

      var _backgroundColor = themeManager.getBackgroundColor();
      this.elaborateColorIntensity(_backgroundColor);

      this.setState({ userInfo: r.user, isReady: true, generalInfo: r.info, tabTitle: r.info.name, paymentInfo: r.payment});
        
    }).catch(e => {
      this.setState({ isReady: true, tabTitle: 'Getfy'});
    });

  }

  elaborateColorIntensity = (bgColor) => {
    return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? this.setState({ logo: 'dark' }) : this.setState({ logo: 'light' });
  }

  render(){
    
    let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

    return (
      <div>

      { this.state.isReady &&
      <div className="App" style={{  marginTop: '0px', backgroundColor: _backgroundColor}}>

      <MetaTags>
      <title>{this.state.tabTitle}</title>      
       <meta name="theme-color" content={_backgroundColor} /> 
       <meta name="description" content={this.state.tabTitle} />
       <meta property="og:title" content={this.state.tabTitle} />
      </MetaTags>
    
            { this.state.generalInfo == null &&
             <div>
                <br />
                <br />
                <br />
                <div style={{ color: _secondaryTextColor, fontSize: '15px', fontWeight: 'normal'}}>La pagina cercata non è disponibile</div>
            </div>
             }
        { this.state.generalInfo != null &&
        <PaymentManager user={this.state.userInfo} info={this.state.generalInfo} payment={this.state.paymentInfo} />
        }
  
    <br />

    { this.state.logo === 'dark' && 
    
    <img alt="Getfy" style={{marginTop: '20px', height: '15px'}} src={logoBlack} />
    }

    { this.state.logo === 'light' && 
     
    <img alt="Getfy" style={{marginTop: '20px', height: '15px'}} src={logoWhite} />
      }
      <br />
      <br />
   
    </div>  
    }  


      </div>
  );
    }
}

export default App;
