import React, { Component } from 'react'
import PaymentDetails from './PaymentDetails';
import ThemeManager from './ThemeManager';

export class PaymentComplete extends Component {

    constructor(props){
        super(props);

        this.state = { info: props.info, payment: props.payment };
    }

    openInvoice = (e) => {
        const newWindow = window.open(this.state.payment.proforma, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();
        const _buttonTextColor = themeManager.getButtonTextColor();

        return (
            <div>



            <div style={{ marginTop: '30px', color: _secondaryTextColor, fontSize: '15px', fontWeight: 'bold'}}>Transazione avvenuta con successo 👍🏻</div> 

            <div style={{marginTop: '15px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>      
            <div style={{marginTop: '0px', width: '250px', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>          
            <div style={{ marginTop: '00px', color: _secondaryTextColor, fontSize: '12px', fontWeight: 'normal'}}>Data: {this.state.payment.payment_date}</div>      
            <div style={{ marginTop: '0px', color: _secondaryTextColor, fontSize: '12px', fontWeight: 'normal'}}>Codice: {this.state.payment.payment_code}</div>    
            </div>
            </div>    

                <PaymentDetails info={this.state.info}  />

                <br />
                <br />


                    <p onClick={this.openInvoice} style={{ cursor: 'pointer', marginTop: '-7px', marginLeft:'10px', marginRight:'4px', color: _selectionColor, fontWeight: 'bold', fontSize: '10px'}}> 
                    vedi ricevuta non fiscale 
                    </p>

                    <p style={{ marginTop: '-7px', marginLeft:'10px', marginRight:'4px', color: _secondaryTextColor, fontWeight: 'bold', fontSize: '10px'}}> 
                    * la ricevuta fiscale verrà rilasciata dal destinatario del pagamento 
                    </p>

            </div>
        )
    }
}

export default PaymentComplete
