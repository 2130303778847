import React, { Component } from 'react'
import ThemeManager from './ThemeManager';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import { BoxLoading, CoffeeLoading } from 'react-loadingg';

export class PaymentForm extends Component {

    constructor(props){
        super(props);

        this.state = { info: props.info, cardNumber: '', cardNumberInitial: '', expiry: '', expiryInitial: '', cvc: '', cardImage: '💳', confirmOpacity: '0.5', canPay: false, openLoader: false, automatic: true};
    }


    toggleLoader = () => {
        this.setState({ openLoader: false })
     }


     goBack = () => {
        this.props.back();
     }
  

    insertCardNumberSpace = (number) => {
        number = String(number);
        if(number.length>3){
            number = number.slice(0, 4) + " " + number.slice(4);
        }

        if(number.length>8){
            number = number.slice(0, 9) + " " + number.slice(9);
        }

        if(number.length>13){
            number = number.slice(0, 14) + " " + number.slice(14);
        }
        
        return number;
    }

    insertCardExpirySlash = (number) => {
        number = String(number);
        if(number.length>1){
            number = number.slice(0, 2) + "/" + number.slice(2);
        }

       
        return number;
    }

  


    insertNumber = (e) => {

        var insert = String(e.target.value);
        
        insert = insert.replaceAll(" ", "");
        
        if(insert==this.state.cardNumberInitial){
            insert = insert.slice(0,-1);
        }

        if(!isNaN(insert)&&e.target.value.length<17){
           // var edited = this.insertCardNumberSpace(insert);
            this.setState({ cardNumber: insert, cardNumberInitial: insert});
            this.validateCard(insert,this.state.cvc,this.state.expiryInitial);
        }else{
            this.validateCard(this.state.cardNumberInitial,this.state.cvc,this.state.expiryInitial);
        }

        if(e.target.value.length>0){
            this.setState({ numberSize: '15px'});
        }else{
            this.setState({ numberSize: '14px'});
        }

       
    }



    insertExpiry = (e) => {

        var insert = String(e.target.value);
        
        insert = insert.replaceAll("/", "");
        
        if(insert==this.state.expiryInitial){
            insert = insert.slice(0,-1);
        }
        
        if(!isNaN(insert)&&e.target.value.length<6&&this.validateExpiration(insert)){
            var edited = this.insertCardExpirySlash(insert);
            this.setState({ expiry: edited, expiryInitial: insert});
            this.validateCard(this.state.cardNumberInitial,this.state.cvc,insert);
        }else{
            this.validateCard(this.state.cardNumberInitial,this.state.cvc,this.state.expiryInitial);
        }

        
       
    }


    validateExpiration = (expiry) => {

        var toValidateString = String(expiry);

        if(toValidateString==""){
            return true;
        }

        
        if(toValidateString.length<3 && parseInt(toValidateString.charAt(0))<2){
            return true;
        }

        if(toValidateString.length>2 && parseInt(toValidateString.charAt(0))<2 && parseInt(toValidateString.charAt(2))==2){
            return true;
        }

        return false;
    }


    insertCVC = (e) => {

        var insert = String(e.target.value);
        
        if(!isNaN(insert)&&e.target.value.length<5){
            this.setState({ cvc: insert});
            this.validateCard(this.state.cardNumberInitial,insert,this.state.expiryInitial);
        }else{
            this.validateCard(this.state.cardNumberInitial,this.state.cvc,this.state.expiryInitial);
        }

       
    }

    validateCard = (card,cvc,expiry) => {

        if(expiry.length===4&&((cvc.length===3&&card.length===16)||(cvc.length===4&&card.length===15))){
            this.setState({ canPay: true, confirmOpacity: '1.0'});
        }else{
            this.setState({ canPay: false, confirmOpacity: '0.5'}); 
        }

    }


    payNow = (e) => {
        if(this.state.canPay){
            this.setState({ canPay: false});

            this.setState({ openLoader: true })
            var automatic = 0;
            if(this.state.automatic){
                automatic = 1;
            }
            this.props.pay(this.state.cardNumberInitial, this.state.cvc, this.state.expiryInitial, automatic);

        }
    }

    checkboxAction = (e) => {

        if(!this.state.automatic){
            this.setState({ automatic: true}); 
        }else{
            this.setState({ automatic: false});  
        }
    }


    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        
        return (
            <div>
                
                <div  style={{marginTop: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: _itemBackgroundColor}}>

            <div style={{  width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _itemBackgroundColor, marginLeft: '0px',
             fontSize: '18px', fontWeight: 'thin'}}>
            {this.state.cardImage}
            </div>
            <div>
                    <input 
                style={{ paddingLeft: '10px', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '170px', borderRadius: '8px', 
                backgroundColor: _itemBackgroundColor, outlineWidth: '0', fontSize: this.state.numberSize}} type="tel"  
                name="card"
                value={this.state.cardNumber}
                placeholder="numero carta"
                onChange={this.insertNumber}
                />
                </div>
                </div>
                </div>


                <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                <div  style={{width: '115px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: _itemBackgroundColor}}>

                    <input 
                style={{ paddingLeft: '0px', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '115px', borderRadius: '8px', 
                backgroundColor: _itemBackgroundColor, outlineWidth: '0', fontSize: '14px', textAlign: 'center'}} type="tel"  
                name="card"
                value={this.state.expiry}
                placeholder="MM/YY"
                onChange={this.insertExpiry}
                />
                
                </div>


                <div  style={{marginLeft: '15px', width: '115px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: _itemBackgroundColor}}>

                    <input 
                style={{ paddingLeft: '0px', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '115px', borderRadius: '8px', 
                backgroundColor: _itemBackgroundColor, outlineWidth: '0', fontSize: '14px', textAlign: 'center'}} type="tel"  
                name="card"
                value={this.state.cvc}
                placeholder="CVC"
                onChange={this.insertCVC}
                />
                
                </div>
              

                </div>
                <div style={{ marginTop: '15px', color: 'rgb(198,210,25)', fontSize: '11px', fontWeight: 'bold'}}>
                🔒 Pagamento sicuro con Stripe
                </div>
                
                
                <div><div  style={{ marginTop: '5px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor, opacity: this.state.confirmOpacity}}
            onClick={this.payNow}
            >

                    Paga ora</p>
                    </div>
                    </div>
                    {this.state.info.billing_require == '1' &&
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.goBack} style={{cursor: 'pointer', fontSize: '14px', color: _secondaryTextColor }}>annulla</div>
                    </div>
                    }

                    <Drawer
                    open={this.state.openLoader}
                        onRequestClose={this.toggleLoader}
                        modalElementClass="modal">
                            <div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)'}}>Stiamo elaborando <br />il tuo pagamento</div>
                    </div>
                   <div style={{marginTop: '60px', height: '50px !important'}}>
                   <BoxLoading color={_selectionColor} />
                   </div>
                   <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '30px', fontSize: '14px', color: 'rgb(60,60,60)'}}>Attendi qualche secondo</div>
                    </div>
                   
                    </div>
                    </Drawer>
            </div>
        )
    }
}

export default PaymentForm
