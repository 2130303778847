import React, { Component } from 'react'
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Drawer from 'react-drag-drawer';
import '../Popupstyle.css';
import { it } from 'date-fns/locale'
import ThemeManager from '../ThemeManager';

export class BirthDate extends Component {

    constructor(props){
        super(props);

        let themeManager = ThemeManager.getInstance();
        var _secondaryTextColor = themeManager.getSecondaryTextColor();

        this.state = { userInfo: props.user,
                        date: new Date("2009-12-31"),
                        openConfirm: false,
                        openConfirmChange: false,
                      birthDateString: '-',
                    isProcessing: false, 
                    day: '0', 
                    month: '0', 
                    year: '0', 
                    error: false,
                    dayColor: _secondaryTextColor,
                    monthColor: _secondaryTextColor,
                    yearColor: _secondaryTextColor};
       
    }//ar date = new Date("2016-01-04 10:34:23");

    toggle = () => {
      this.setState({ openConfirm: false, openConfirmChange: false })
   }

   onConfirmSet = (date) => {

    var textualDate = new Intl.DateTimeFormat("it-IT", {
      year: "numeric",
        month: "long",
        day: "2-digit"
    }).format(date);


      this.setState({ date: date, birthDateString: textualDate, openConfirm: true });
  }

  changeYear = (e) => {
    let themeManager = ThemeManager.getInstance();
    var _mainTextColor = themeManager.getMainTextColor();
    var _secondaryTextColor = themeManager.getSecondaryTextColor();

    var newValue = e.target.value;
    var colorValue = _mainTextColor;
    if(newValue==="0"){
      colorValue = _secondaryTextColor;
    }
    this.setState( { year: newValue, error: false, yearColor: colorValue });

  }

  changeMonth = (e) => {
    let themeManager = ThemeManager.getInstance();
    var _mainTextColor = themeManager.getMainTextColor();
    var _secondaryTextColor = themeManager.getSecondaryTextColor();

    var newValue = e.target.value;
    var colorValue = _mainTextColor;
    if(newValue==="0"){
      colorValue = _secondaryTextColor;
    }
    this.setState( { month: newValue, error: false, monthColor: colorValue  });

  }

  changeDay = (e) => {
    let themeManager = ThemeManager.getInstance();
    var _mainTextColor = themeManager.getMainTextColor();
    var _secondaryTextColor = themeManager.getSecondaryTextColor();

    var newValue = e.target.value;
    var colorValue = _mainTextColor;
    if(newValue==="0"){
      colorValue = _secondaryTextColor;
    }
    this.setState( { day: newValue, error: false, dayColor: colorValue });

  }

  confimDate = (e) => {
    
    if(this.state.day>0 && this.state.day<32 && this.state.month>0 && this.state.month<13 && this.state.year>1900 && this.state.year<2011){
      var datestring = this.state.year+'/'+this.state.month+'/'+this.state.day;
      var date = new Date(datestring);
      this.onConfirmSet(date);
    }else{
      this.setState({  error: true})
    }
    
  }

    confirmChange = (e) => {
      this.setState({ openConfirmChange: true});
    }

    changeDate = (e) => {

      if(!this.setState.isProcessing){

        this.setState({ isProcessing: true});
        var date = '0000-00-00';
        var sessiontoken = localStorage.getItem('session_token');
        if(sessiontoken === null){
          sessiontoken = '';
        }
    
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
                    date: date,
                    token: sessiontoken
                  })
          };
        
              const apiUrl = 'https://getfy-business.com/user/v3/user/set/birth';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => { 
            window.location.reload();
        }).catch(e => {
            window.location.reload();
        });
      }
    }

    confirm = (e) => {
      if(!this.setState.isProcessing){
       
        this.setState({ isProcessing: true});

        var date = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          }).format(this.state.date);
          
        var sessiontoken = localStorage.getItem('session_token');
        if(sessiontoken === null){
          sessiontoken = '';
        }
    
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
                    date: date,
                    token: sessiontoken
                  })
          };
        
              const apiUrl = 'https://getfy-business.com/user/v3/user/set/birth';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => { 
            window.location.reload();
        }).catch(e => {
            window.location.reload();
        });

      }
    }

    render() {


      let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

      const days = [];
      for(var i=1; i<32; i++){
        days.push(i);
      }

      const years = [];
      for(var i=2010; i>1909; i--){
        years.push(i);
      }


        return (
            <div>

            { this.state.userInfo.birth === 'need' &&
              <div>
              <div style={{marginTop: '40px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _secondaryTextColor, fontSize: '12px'}}>
            inserisci la tua <p style={{ color: _selectionColor, marginLeft: '8px', marginRight: '8px', fontSize: '14px', fontWeight: 'bold'}}>data di nascita</p>per procedere
            </div>
                
            <select className="nosafari" style={{ marginTop: '20px', width: '250px', height: '45px', backgroundColor: _itemBackgroundColor,
             color: this.state.dayColor, border: '0px solid', borderRadius: '8px', fontWeight: 'normal'
             }} value={this.state.day} onChange={this.changeDay}>
            <option value="0">giorno di nascita</option>
            { days.map((day, i) =>
                <option key={i} value={day}>{day}</option>
                )
            }
            
            </select>
            <br />
            <select className="nosafari" style={{ marginTop: '15px', width: '250px', height: '45px', backgroundColor: _itemBackgroundColor,
             color: this.state.monthColor, border: '0px solid', borderRadius: '8px', fontWeight: 'normal'
             }} value={this.state.month} onChange={this.changeMonth}>
            <option value="0">mese di nascita</option>
            <option value="1">Gennaio</option>
            <option value="2">Febbraio</option>
            <option value="3">Marzo</option>
            <option value="4">Aprile</option>
            <option value="5">Maggio</option>
            <option value="6">Giugno</option>
            <option value="7">Luglio</option>
            <option value="8">Agosto</option>
            <option value="9">Settembre</option>
            <option value="10">Ottobre</option>
            <option value="11">Novembre</option>
            <option value="12">Dicembre</option>
            </select>
            <br />
            <select className="nosafari" style={{ marginTop: '15px', width: '250px', height: '45px', backgroundColor: _itemBackgroundColor,
             color: this.state.yearColor, border: '0px solid', borderRadius: '8px', fontWeight: 'normal'
             }} value={this.state.state} onChange={this.changeYear}>
            <option value="0">anno di nascita</option>
            { years.map((year, i) =>
                <option key={i} value={year}>{year}</option>
                )
            }
            
            </select>


                <div  style={{marginTop: '25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '45px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.confimDate}
            >
                    Conferma</p>
                    </div>
                    </div>
            }

{ this.state.error && <p style={{ marginTop: '30px', color: _errorColor, fontSize: '12px', fontWeight: 'bold'}}>inserisci la tua data di nascita</p> }
          { this.state.userInfo.birth !== 'need' &&
              <div>
             
            <div  style={{marginTop: '80px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <div>
                    <div onClick={this.confirmCancel} style={{marginTop: '20px', fontSize: '15px', color: _mainTextColor }}>per poter procedere devi aver compiuto</div>
                    <div onClick={this.confirmCancel} style={{marginTop: '-12px', fontSize: '15px', color: _mainTextColor, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    almeno <p style={{ marginLeft: '10px', marginRight: '10px', fontWeight: 'bold', color: _selectionColor }}>{this.state.userInfo.birth}</p>anni
                      </div>
                    </div>
                    </div>
          
            
                <div  style={{marginTop: '25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '45px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.confirmChange}
            >
                    Cambio</p>
                    </div>
                    </div>
            }


                    <br />
                    <br />
                    <br />

                    <Drawer
                    open={this.state.openConfirm}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <div>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>Confermi di essere nato il</div>
                    <div style={{marginTop: '-12px', fontSize: '14px', color: 'rgb(60,60,60)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <p style={{ fontWeight: 'bold'}}>{this.state.birthDateString}</p>?
                      </div>
                    </div>
                    </div>
                    <div  style={{marginTop: '15px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor }}
            onClick={this.confirm}
            >Conferma</p>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)' }}>annulla</div>
                    </div>
                    </div>
                    </Drawer>


                    <Drawer
                    open={this.state.openConfirmChange}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>
                      Vuoi cambiare la tua data di nascita?<br />Inserire una data di nascita falsa infrange il regolamento di Getfy.</div>                   
                    </div>
                    <div  style={{marginTop: '10px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.changeDate}
            >Cambia</p>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)' }}>annulla</div>
                    </div>
                    </div>
                    </Drawer>
              
            </div>
        )
    }
}

export default BirthDate
