import React, { Component } from 'react'
import ThemeManager from './ThemeManager';

export class MainInfo extends Component {



    constructor(props){
        super(props);
        this.state = { info: props.info};
        
    }


    render() {

        let themeManager = ThemeManager.getInstance();
        const _mainTextColor = themeManager.getMainTextColor();
        
        return (
            <div>
                
                <br />               
            { this.state.info.name !== '' &&
            <div style={{marginTop: '0px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
            <div style={{ borderRadius: '8px', color: _mainTextColor, fontSize: '24px', fontWeight: 'bold', textAlign: 'center'}}>
            {this.state.info.name}           
            </div>        
            </div>
            }

           
                
            </div>
        )
    }
}

export default MainInfo

/*
                <div style={{ position: 'absolute', marginTop: '-270px', background: 'linear-gradient(rgb(255,255,255), transparent)', width: '100%', height: '250px'}}>

                </div>
                */