import React, { Component } from 'react'
import ThemeManager from './ThemeManager';

export class PaymentDetails extends Component {


    constructor(props){
        super(props);

        this.state = { info: props.info };
    }


    render() {

        let themeManager = ThemeManager.getInstance();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();

        return (
            <div>
                <div style={{ marginTop: '50px', color: _secondaryTextColor, fontSize: '15px', fontWeight: 'normal'}}>{this.state.info.description}</div>
                <div style={{ marginTop: '25px', color: _mainTextColor, fontSize: '30px', fontWeight: 'bold'}}>€ {(parseFloat(this.state.info.amount)/100).toFixed(2)}</div>
                { this.state.info.show_tax == '1' &&
                <div style={{ marginTop: '10px', color: _secondaryTextColor, fontSize: '15px', fontWeight: 'normal'}}>di cui IVA € {(parseFloat(this.state.info.amount)/100-parseFloat(this.state.info.amount)/122).toFixed(2)}</div>
                }
            </div>
        )
    }
}

export default PaymentDetails
