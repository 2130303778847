import React, { Component } from 'react'
import PaymentBilling from './PaymentBilling';
import PaymentDetails from './PaymentDetails';
import PaymentForm from './PaymentForm';
import ThemeManager from './ThemeManager';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import isok from './isok.png';
import notok from './notok.png';
import { BoxLoading, CoffeeLoading } from 'react-loadingg';

export class Payment extends Component {

    constructor(props){
        super(props);

        var billingOk = true;
        if(props.info.billing_require == '1'){
            billingOk = false;
        }

        this.state = { payment_token: props.token, info: props.info, billingOk: billingOk, billingInfo: [], canPay: true, openFail: false, failText: 'Riprova ora', openConfirmSecure: false, openSuccess: false };
    }


    setBillingInfo = (info) => {
        
        this.setState({ billingInfo: info, billingOk: true });
    }
   
    showBillingInfo = () => {

        this.setState({ billingOk: false });
    }

    toggle = () => {
        window.location.reload();
     }

     goToBankSite = (e) => {

        window.location.href = this.state.redirectUrl;
      
      }

    pay = (card, cvc, expiry) => {
        if(this.state.canPay){

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                window.location.reload();
                return
            }
            this.setState({ canPay: false});
        
            const requestOptions = {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
              }),
              body: JSON.stringify({
                        token: sessiontoken,
                        payment_token:  this.state.payment_token,
                        card: card,
                        cvc: cvc,
                        expiry: expiry,
                        billing: this.state.billingInfo
                      })
              };
            
                  const apiUrl = 'https://getfy-business.com/user/v3/pay/';
        
            fetch(apiUrl, requestOptions)
            .then(r => r.json())
            .then(r => {
                
                if(r.info.status=='done'){
                    this.setState({ openSuccess: true });
                }else if(r.info.status=='3D'){
                    //prosegui banca popup
                    this.setState({ openConfirmSecure: true, redirectUrl: r.info.url });
                }else if(r.info.status=='error'){
                    //prosegui banca popup
                    this.setState({ openFail: true, failText: r.info.text });
                }else{
                    this.setState({ openFail: true, failText: 'Riprova ora' });
                }
                
              
            }).catch(e => {
                
                this.setState({ openFail: true });
            });
        }
    }

 

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        
        return (
            <div>
                
                <PaymentDetails info={this.state.info}  />

                { !this.state.billingOk && 
                <PaymentBilling setInfo={this.setBillingInfo} />
                }

                { this.state.billingOk && 
                <PaymentForm pay={this.pay} back={this.showBillingInfo} info={this.state.info}  />
                }

                    <Drawer
                    open={this.state.openSuccess}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div  onClick={this.toggle}>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)'}}>Pagamento avvenuto con successo</div>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{marginTop: '10px'}} src={isok} />
                    </div>
                   
                   <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '10px', fontSize: '14px', color: 'rgb(60,60,60)'}}>Mostra riepilogo</div>
                    </div>
                   
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.openFail}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div  onClick={this.toggle}>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)'}}>Qualcosa è andato storto<br /> il pagamento non è stato effettuato</div>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{marginTop: '15px', width: '70px'}} src={notok} />
                    </div>
                   
                   <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '15px', fontSize: '14px', color: 'rgb(192,0,0)'}}>{this.state.failText}</div>
                    </div>
                   
                    </div>
                    </Drawer>


                    <Drawer
                    open={this.state.openConfirmSecure}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div  onClick={this.goToBankSite}>
                            <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)'}}>Per confermare il pagamento verrai <br /> reindirizzato al sito della tua banca</div>
                    </div>
                  
                    <div  style={{marginTop: '25px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'rgb(150,51,205)', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.goToBankSite}
            >Prosegui</p>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)'}}>annulla</div>
                    </div>
                    </div>
                    </Drawer>

            </div>
        )
    }
}

export default Payment
